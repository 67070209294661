import React, { Component } from "react";
import { Link } from "gatsby";
import Header from "./header"
import Footer from "./footer"

import ListingOne from "../../images/listing-one.svg";
import ListingTwo from "../../images/listing-two.svg";
import ListingThree from "../../images/listing-three.svg";
import ListingFour from "../../images/listing-four.svg";
import ListingFive from "../../images/listing-five.svg";
import ListingSix from "../../images/listing-six.svg";
import BannerImg from "../../images/banner.svg";
import TrustedOne from "../../images/trusted-one.svg";
import TrustedTwo from "../../images/trusted-two.svg";
import TrustedThree from "../../images/trusted-three.svg";
import TrustedFour from "../../images/trusted-four.svg";
import CheckoutBack from "../../images/checkout-back.svg";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL
    return (
      <div>
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-100">
              <div className="left-section">
                <span className="checkoutbtn-wrapper">
                  <a href={redirectUrl} target='_blank'>
                    <div className="checkoutbtn">
                      <div className="greenlabel">New</div>
                      <div className="checkoutbtn-txt">Check out Curbwaste - our waste management software <img src={CheckoutBack} alt="" /></div>
                    </div>
                  </a>
                </span>
                <h1>The world's most modern scale software</h1>
                <p>The first cloud based system to track all material going in and out of your facility in real time. </p>
                <Link className="btn btn-demo" to={`/signup`}>Request Demo</Link>
                <div className="trusted-wrapper">
                  <h4>Trusted By</h4>
                  <div className="trusted">
                    <div><img src={TrustedOne} alt="" /></div>
                    <div><img src={TrustedTwo} alt="" /></div>
                    <div><img src={TrustedThree} alt="" /></div>
                    <div><img src={TrustedFour} alt="" /></div>
                  </div>
                </div>
              </div>
              <div className="right-section">
                <img src={BannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="listing-bg">
                <h3>Why businesses choose WeighWorks</h3>
                <ul>
                  <li>
                    <img src={ListingOne} alt="" />
                    <h3>Automated invoices</h3>
                    <p>Our billing integration allows for real time invoice generation, saving you time and money and making sure nothing is missed. </p>
                  </li>
                  <li>
                    <img src={ListingTwo} alt="" />
                    <h3>Eliminate fraud </h3>
                    <p>With different user accessiblity levels you can monitor your entire operation with the click of a button and an audit log of every transaction at the facility. </p>
                  </li>
                  <li>
                    <img src={ListingThree} alt="" />
                    <h3>High level reporting</h3>
                    <p>WeighWorks captures both inbound and outbound materials allowing you to analyze your material breakdowns and profit margins in real time. </p>
                  </li>


                  <li>
                    <img src={ListingFour} alt="" />
                    <h3>Integration with 99% scales in market</h3>
                    <p>WeighWorks integrates with a number of scales within the market, allowing for a seamless onboarding process. </p>
                  </li>
                  <li>
                    <img src={ListingFive} alt="" />
                    <h3>Video monitoring of every load</h3>
                    <p>Our camera integration allows you to get images of every load that comes into your facility eliminating the guess work and enhancing quality control. </p>
                  </li>
                  <li>
                    <img src={ListingSix} alt="" />
                    <h3>Driver Verification </h3>
                    <p>Our one of a kind identification integration automatically scans the drivers licenses of everybody who comes to your facility allowing for the best in class regulatory process. </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid get-started">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>Get started with WeighWorks today</h3>
                <p>Request a demo to learn more about the most advanced scale software available in the market. </p>
                <Link className="btn btn-demo" to={`/signup`}>Request Demo</Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

    );
  }
}

export default Homepage;
